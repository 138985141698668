import React from 'react';
import {axiosinstance, axiosCatch} from '../../components/axios';
import DedicatedServersListInstant from './productListInstant';
import PlanPreLoader from '../../components/planPreloader';
import AddonsDedicatedServers from './addonsListInstant';
import LoadingError from '../../components/loadingError';
import lscache from 'lscache';
import Select from 'react-select';

class DynamicContentInstant extends React.Component {

	constructor(props, context) {
		super(props, context);

        this.state = {
            products: [],
			error: false,
            loading: true,
            locations: [],
            location: [{
                "short": "ZUR",
                "name": "Zürich",
                "region": "Europe",
                "country_code": "CH",
                "value": "ZUR",
                "label": "Zürich"
            }],
            cpuCores: [
                {
                    value: '4 – 8 cores',
                    label: '4 – 8 cores',
                    min: 4,
                    max: 8,
                },
                {
                    value: '8 – 16 cores',
                    label: '8 – 16 cores',
                    min: 8,
                    max: 16,
                },
                {
                    value: '16 – 32 cores',
                    label: '16 – 32 cores',
                    min: 16,
                    max: 32,
                },
                {
                    value: '32 – 64 cores',
                    label: '32 – 64 cores',
                    min: 32,
                    max: 64,
                },
                {
                    value: '64+ cores',
                    label: '64+ cores',
                    min: 64,
                    max: 100000,
                }
            ],
            cpuCore: [],
            storages: [
                {
                    value: '1 GB – 999 GB',
                    label: '1 GB – 999 GB',
                    min: 1,
                    max: 999,
                },
                {
                    value: '1 TB – 1.99 TB',
                    label: '1 TB – 1.99 TB',
                    min: 1000,
                    max: 1999,
                },
                {
                    value: '2 TB – 3.99 TB',
                    label: '2 TB – 3.99 TB',
                    min: 2000,
                    max: 3999,
                },
                {
                    value: '4 TB – 5.99 TB',
                    label: '4 TB – 5.99 TB',
                    min: 4000,
                    max: 5999,
                },
                {
                    value: '6 TB – 7.99 TB',
                    label: '6 TB – 7.99 TB',
                    min: 6000,
                    max: 7999,
                },
                {
                    value: '8 TB – 9.99 TB',
                    label: '8 TB – 9.99 TB',
                    min: 8000,
                    max: 9999,
                },
                {
                    value: '10 TB – 14.99 TB',
                    label: '10 TB – 14.99 TB',
                    min: 10000,
                    max: 14999,
                },
                {
                    value: '15 TB – 19.99 TB',
                    label: '15 TB – 19.99 TB',
                    min: 15000,
                    max: 19999,
                },
                {
                    value: '20 TB – 24.99 TB',
                    label: '20 TB – 24.99 TB',
                    min: 20000,
                    max: 24999,
                },
            ],
            storage: [],
            manufacturers: [],
            manufacturer: [],
            type: 'cpu',
            isStock: true,
        }
    }

    handleJsonLdGenerated = (JSON) => {
        this.props.onJsonLdGenerated(JSON)
    }

	componentDidMount () {

		this.load();
	}

	load = () => {

        this.setState({
            loading: true
        }, () => {

            if (lscache.get('instantProducts') === null)
            {

            axiosinstance({
                method: 'get',
                url: process.env.REACT_APP_BACKEND_URL + "/products/smart_servers.en.json"
            })
                .then((response) => {
                    if (response.status === 200) {
                        if (response.data.result === true) {
                            const productData = response.data
                            const configData = JSON.parse(productData?.data?.products?.[0]?.configurations || '');
                            let configs = configData.configs.map((item) => item.reduce((pred, cur, i) => ({
                                ...pred,
                                [configData.fields[i]]: cur,
                            }), {}));
                            const locations = configData?.dicts?.locations?.map((item) => ({
                                ...item,
                                value: item?.short,
                                label: item?.name
                            })) || []
                            const cpu = configData?.dicts?.cpu?.map((item) => ({
                                value: item,
                                label: item,
                                data: {
                                    cpu_count: item.split('x').map(e => e.trim())[0],
                                }
                            })) || []
                            const cpu_det = configData?.dicts?.cpu_det?.map((item) => ({
                                ...item
                            })) || []
                            const storage = configData?.dicts?.storage?.map(item => {
                                const parsed = JSON.parse(item);
                                const parsedArray = parsed.map(entry => {
                                    const number = entry[0] || 0;
                                    const type = entry[1] || '';
                                    const gb = entry[2] || 0;
                                    return {
                                        number,
                                        type,
                                        gb,
                                        allGb: number * gb,
                                    };
                                });
                                const totalAllGb = parsedArray.reduce((sum, entry) => sum + entry.allGb, 0);

                                return {
                                    allGb: totalAllGb,
                                    data: parsedArray
                                };
                            });
                            const gpu = configData?.dicts?.gpu || []
                            const uniqueManufacturers = [...new Set(configData?.dicts?.cpu_det.map(processor => processor.manufacturer))];
                            const manufacturers = uniqueManufacturers.map(manufacturer => ({
                                value: manufacturer,
                                label: manufacturer
                            }))
                            configs = configs?.map((item) => ({
                                ...item,
                                cpu_det: cpu_det?.[item?.cpu_det] || '',
                                cpu: cpu?.[item?.cpu] || '',
                                storage: storage?.[item?.storage] || '',
                                gpu: gpu?.[item?.gpu] || '',
                                name: [
                                    `${cpu?.[item?.cpu]?.data?.cpu_count || '-'} x ${cpu_det?.[item?.cpu_det]?.name || '-'}`,
                                    `${item?.ram_gb || '-'} GB`,
                                    ...(storage?.[item?.storage]?.data?.map((e) =>
                                        `${e?.number || '1'} x ${e?.gb || '0'} GB ${e?.type || ''}`
                                    ) || [])
                                ].join(", ")
                            })) || []
                            const addons = Object.entries(productData?.data?.addons).map(([key, value]) => {
                                const items = Array.isArray(value) ? value : [value];
                                return {
                                    key: key,
                                    items: items
                                };
                            });
                            const result = {
                                ...productData,
                                data: {
                                    ...productData?.data,
                                    products: configs,
                                    locations: locations,
                                    manufacturers: manufacturers,
                                    addons,
                                }
                            }
                            lscache.set('instantProducts', result, 10);
                            this.setState({
								products: result?.data?.products || [],
                                addons: result?.data?.addons,
                                configCount: result?.data?.products.length,
                                locations: result?.data?.locations,
                                manufacturers: result?.data?.manufacturers,
                                loading: false
                            })

                        } else {
                            this.setState({
                                error: true,
                                loading: false
                            });
                        }
                    } else {
                        this.setState({
                            error: true,
                            loading: false
                        });
                    }
                }).catch((args) => {
                axiosCatch(args, this.props);
            });

        }

        else {
            this.setState({
                products: lscache.get('instantProducts')?.data?.products || [],
                addons: lscache.get('instantProducts')?.data?.addons,
                configCount: lscache.get('instantProducts')?.data?.products.length,
                locations: lscache.get('instantProducts')?.data?.locations,
                manufacturers: lscache.get('instantProducts')?.data?.manufacturers,
                loading: false
                })

        }

            if (lscache.get('exchangeRates') === null)
            {

            axiosinstance.get(process.env.REACT_APP_BACKEND_URL + "/variables.json")
            .then(res => {

                if (res.status === 200 && res.data.result === true) {
              this.setState({
                usd:  res.data.data.currencies.find(x => x.short_name === 'USD').rate,
                eur:  res.data.data.currencies.find(x => x.short_name === 'EUR').rate,
                btc:  res.data.data.currencies.find(x => x.short_name === 'BTC').rate
                })
                lscache.set('exchangeRates', res, 3);
            }

            else {
                this.setState({
                    error: true,
                    loading: false
                });
            }

            })
            }
            else {
                this.setState({
                    usd: lscache.get('exchangeRates').data.data.currencies.find(x => x.short_name === 'USD').rate,
                    eur: lscache.get('exchangeRates').data.data.currencies.find(x => x.short_name === 'EUR').rate,
                    btc: lscache.get('exchangeRates').data.data.currencies.find(x => x.short_name === 'BTC').rate
                    })
            }

        });
	};

    locationFilterOnChange = (selected) => {
        this.setState({
            location: selected
        })
    }

    selectTypeFilter = (selected) => {
        this.setState(prevState => ({
            ...prevState,
            type: selected
        }))
    }

    selectDeliveryFilter = (selected) => {
        this.setState(prevState => ({
            ...prevState,
            isStock: selected
        }))
    }

    cpuCoresFilterOnChange = (selected) => {
        this.setState(prevState => ({
            ...prevState,
            cpuCore: selected
        }))
    }

    storageFilterOnChange = (selected) => {
        this.setState(prevState => ({
            ...prevState,
            storage: selected
        }))
    }

    manufacturerFilterOnChange = (selected) => {
        this.setState(prevState => ({
            ...prevState,
            manufacturer: selected
        }))
    }

    countConfigs = (value) =>
    {
        if (this.state.configCount !== value) 
        {
        this.setState({
            configCount: value            
            })
        }
    }

    isResetDisabled = () => {
        const isDefaultLocation = this.state.location.length === 1 &&
            this.state.location[0].value === "ZUR";
        return isDefaultLocation &&
            this.state.cpuCore.length === 0 &&
            this.state.storage.length === 0 &&
            this.state.manufacturer.length === 0 &&
            this.state.type === 'cpu' &&
            this.state.isStock === true;
    };

    resetFilters = () => {
        this.refs.locationSelectRef.clearValue();
        this.refs.cpuCoresSelectRef.clearValue();
        this.refs.storageCoresSelectRef.clearValue();
        this.refs.manufacturerSelectRef.clearValue();
        this.setState((prevState) => ({
            ...prevState,
            location: [{
                "short": "ZUR",
                "name": "Zürich",
                "region": "Europe",
                "country_code": "CH",
                "value": "ZUR",
                "label": "Zürich"
            }],
            cpuCore: [],
            storage: [],
            manufacturer: [],
            type: 'cpu',
            isStock: true,
        }));
    }

	render() {          

        if (this.state.loading) {
            return (
					<PlanPreLoader/>
            )
        }
        if (this.state.error) {
            return (

                <LoadingError/>
            )
        } else {

			return (
<React.Fragment>


    <div className="fullwidth instant-servers">
        <div className="row">
            <div className="twelve columns">
                <fieldset className="filter-container-dedicated">
                    <legend>
                        <strong>{this.state.configCount}</strong> Server{this.state.configCount !== 1 && 's'} found <button disabled={this.isResetDisabled()}
                        onClick={this.resetFilters} className={this.isResetDisabled() && 'reset--disabled'}><i className="fa-solid fa-rotate-left"></i> reset</button>
                    </legend>
                    <div className="row">
                        <div className="four columns">
                            <div className='slider-legend slider-price'><span className='title'>Type: </span></div>
                            <div className={'instant-selector'}>
                                <div onClick={() => this.selectTypeFilter('cpu')}
                                     className={this.state?.type === 'cpu' ? 'instant-selector__item active' : 'instant-selector__item'}>CPU
                                    servers
                                </div>
                                <div onClick={() => this.selectTypeFilter('gpu')}
                                     className={this.state?.type === 'gpu' ? 'instant-selector__item active' : 'instant-selector__item'}>GPU
                                    servers
                                </div>
                            </div>
                        </div>
                        <div className="four columns">
                            <div className='slider-legend slider-price'><span className='title'>Delivery: </span>
                            </div>
                            <div className={'instant-selector'}>
                                <div onClick={() => this.selectDeliveryFilter(true)}
                                     className={this.state?.isStock ? 'instant-selector__item active' : 'instant-selector__item'}>
                                    In stock
                                </div>
                                <div onClick={() => this.selectDeliveryFilter(false)}
                                     className={!this.state?.isStock ? 'instant-selector__item active' : 'instant-selector__item'}>
                                    Up to 48 hours delivery
                                </div>
                            </div>
                        </div>
                        <div className="four columns">
                            <div className='slider-legend slider-price'><span className='title'>Location: </span>
                            </div>
                            <Select
                                isMulti
                                closeMenuOnSelect={false}
                                hideSelectedOptions={false}
                                className={'r-select'}
                                ref={'locationSelectRef'}
                                value={this.state.location}
                                options={this.state.locations}
                                onChange={this.locationFilterOnChange}
                                menuPortalTarget={document.body}
                                styles={{
                                    menuPortal: base => ({...base, zIndex: 9999}),
                                    option: base => ({...base, fontSize: '1em'})
                                }}
                            />
                        </div>
                    </div>
                    <div className="row margin-top">
                        <div className="four columns">
                            <div className='slider-legend slider-price'><span className='title'>CPU cores: </span>
                            </div>
                            <Select
                                isMulti
                                closeMenuOnSelect={false}
                                hideSelectedOptions={false}
                                className={'r-select'}
                                ref={'cpuCoresSelectRef'}
                                options={this.state.cpuCores}
                                onChange={this.cpuCoresFilterOnChange}
                                menuPortalTarget={document.body}
                                styles={{
                                    menuPortal: base => ({...base, zIndex: 9999}),
                                    option: base => ({...base, fontSize: '1em'})
                                }}
                            />

                        </div>
                        <div className="four columns">
                            <div className='slider-legend slider-price'><span className='title'>Storage: </span>
                            </div>
                            <Select
                                isMulti
                                closeMenuOnSelect={false}
                                hideSelectedOptions={false}
                                className={'r-select'}
                                ref={'storageCoresSelectRef'}
                                options={this.state.storages}
                                onChange={this.storageFilterOnChange}
                                menuPortalTarget={document.body}
                                styles={{
                                    menuPortal: base => ({...base, zIndex: 9999}),
                                    option: base => ({...base, fontSize: '1em'})
                                }}
                            />
                        </div>
                        <div className="four columns">
                            <div className='slider-legend slider-price'><span className='title'>CPU: </span></div>
                            <Select
                                isMulti
                                closeMenuOnSelect={false}
                                hideSelectedOptions={false}
                                className={'r-select'}
                                ref={'manufacturerSelectRef'}
                                options={this.state.manufacturers}
                                onChange={this.manufacturerFilterOnChange}
                                menuPortalTarget={document.body}
                                styles={{
                                    menuPortal: base => ({...base, zIndex: 9999}),
                                    option: base => ({...base, fontSize: '1em'})
                                }}
                            />
                        </div>
                    </div>
                </fieldset>

                <div className="config-container-sorting">
                    <DedicatedServersListInstant
                        usdRate={this.state.usd}
                        eurRate={this.state.eur}
                        btcRate={this.state.btc}
                        list={this.state.products}
                        countConfigs={this.countConfigs}
                        onJsonLdGenerated={this.handleJsonLdGenerated}
                        locationItem={this.state.location}
                        cpuCore={this.state.cpuCore}
                        storage={this.state.storage}
                        manufacturer={this.state.manufacturer}
                        type={this.state.type}
                        isStock={this.state.isStock}
                        osTemplates={this.state.addons.filter((a) => (a.key === 'operating-system' ))}
                    />
                </div>
            </div>
        </div>

    </div>
    <AddonsDedicatedServers addons={this.state.addons} eurRate={this.state.eur} usdRate={this.state.usd} btcRate={this.state.btc}/>
</React.Fragment>
            )
        }
    }
}

export default DynamicContentInstant;
