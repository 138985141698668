import React, {Component} from 'react';
import { withRouter } from 'react-router-dom';

class DedicatedServersListInstant extends Component {

    generateJsonLd(products) {
        const jsonLdProducts = products.map((product) => {
        const usdRate = this.props.usdRate,
        eurRate = this.props.eurRate,
        osTemplates = this.props.osTemplates[0].items.map((os) => os.name).filter((os) => os !== "No OS installation is required");

        return {
            "@type": "Product",
            "name": product.name,
            'sku': product.id.toString(),
            "image": "https://swissmade.host/images/products/dedicated-server-product.png",
            'brand': {
                '@type': 'Brand',
                'name': 'SWISSMADE.HOST'
            },
            "description": `A Swiss-based dedicated server with ${product?.cpu?.label} CPU, ${product?.ram_gb || '0'} GB of RAM, ${product?.storage?.data?.[0]?.number} x ${product?.storage?.data?.[0]?.gb} GB ${product?.storage?.data?.[0]?.type} of storage and free KVM/IPMI.`,
            "offers": {
                "@type": "Offer",
                'url': `https://member.swissmade.host/en/cart/add/${(+(product?.id || 0) + 10000).toString()}`,
                "priceCurrency": "CHF",
                "price": product.price.toString(),
                "priceValidUntil": new Date(new Date().setFullYear(new Date().getFullYear() + 1)).toISOString().split('T')[0],
                "priceSpecification": [
                    {
                      "@type": "UnitPriceSpecification",
                      "price": parseFloat((product.price * (Number(usdRate))).toFixed(2)).toString(),
                      "priceCurrency": "USD"
                    },
                    {
                      "@type": "UnitPriceSpecification",
                      "price": parseFloat((product.price * (Number(eurRate))).toFixed(2)).toString(),
                      "priceCurrency": "EUR"
                    }
                  ],
                "areaServed": "Worldwide",
                "availability": "https://schema.org/InStock",
                'seller': {
                    '@type': 'Organization',
                    'name': 'SWISSMADE.HOST'
                },
                'category': 'Dedicated Server Hosting',
                'itemCondition': 'https://schema.org/NewCondition'
            },
            "additionalProperty": [
                {
                    "@type": "PropertyValue",
                    "name": "CPU model",
                    "value": `${product.cpu_det.manufacturer} ${product.cpu_det.name}`
                  },
                  {
                    "@type": "PropertyValue",
                    "name": "CPU cores",
                    "value": product.cpu_det.cores.toString()
                  },
                  {
                    "@type": "PropertyValue",
                    "name": "CPU frequency",
                    "value": `${(product?.cpu_det?.frequency || 0).toString()} GHz`
                  },
                  {
                      "@type": "PropertyValue",
                      "name": "RAM",
                      "value": `${(product?.ram_gb || 0).toString()} GB`
                  },
                  {
                      "@type": "PropertyValue",
                      "name": "Storage",
                      "value": `${(product?.storage?.allGb || 0).toString()} GB`,
                  },
                  {
                    "@type": "PropertyValue",
                    "name": "Total storage capacity",
                    "value": `${(product?.storage?.allGb || 0).toString()} GB`,
                },
                {
                    "@type": "PropertyValue",
                    "name": "GPU",
                    "value": product.gpu ? product.gpu : 'Not available'
                },
                {
                    "@type": "PropertyValue",
                    "name": "Bandwidth",
                    "value": "15 TB monthly"
                },
                {
                    "@type": "PropertyValue",
                    "name": "IP addresses",
                    "value": "1 IPv4 address"
                },
                {
                    "@type": "PropertyValue",
                    "name": "DDoS protection",
                    "value": "Unmetered, free of charge"
                },
                {
                    "@type": "PropertyValue",
                    "name": "KVM/IPMI",
                    "value": "Included free of charge"
                },
                {
                    "@type": "PropertyValue",
                    "name": "Supported operating systems",
                    "value": osTemplates
                },
                {
                    "@type": "PropertyValue",
                    "name": "Support",
                    "value": "24/7 live chat and support tickets"
                },
                {
                    "@type": "PropertyValue",
                    "name": "Full disk encryption",
                    "value": "Supported"
                },
                {
                    "@type": "PropertyValue",
                    "name": "Location",
                    "value": "Zurich, Switzerland"
                }
            ]
        }
    });

        return (

            {
                "@context": "https://schema.org/",
                "@type": "ItemList",
                "name": "VPS offerings by Swiss Made Host",
                "itemListElement": [jsonLdProducts]
            }

        )
}

render() {
    const list = this.props.list,
        usdRate = this.props.usdRate,
        eurRate = this.props.eurRate,
        location = this.props.locationItem === undefined || this.props.locationItem.length === 0 ? list.map(item => item.location_short) : this.props.locationItem.map(element => element.value),
        cpuCore = this.props.cpuCore,
        storage = this.props.storage,
        manufacturer = this.props.manufacturer === undefined || this.props.manufacturer.length === 0 ? list.map(item => item?.cpu_det?.manufacturer) : this.props.manufacturer.map(element => element.value),
        type = this.props.type,
        isStock = this.props.isStock,
        countConfigs = this.props.countConfigs,
        FilterConditions =
            list.filter((a) => Object.values(location).includes(a.location_short) && Object.values(manufacturer).includes(a.cpu_det?.manufacturer))
                .filter((a) => {
                    if (cpuCore && cpuCore.length > 0) {
                        const itemCores = +(a.cpu_det?.cores || 0);
                        return cpuCore.some((core) => itemCores >= core.min && itemCores <= core.max);
                    }
                    return true;
                })
                .filter((a) => {
                    if (storage && storage.length > 0) {
                        const itemStorage = +(a.storage?.allGb || 0);
                        return storage.some((core) => itemStorage >= core.min && itemStorage <= core.max);
                    }
                    return true;
                })
                .filter((a) => {
                    if (type === 'gpu') {
                        return a?.gpu;
                    } else {
                        return !a.gpu;
                    }
                })
                .filter((a) => {
                    if (isStock) {
                        return !a?.long_delivery;
                    } else {
                        return a.long_delivery;
                    }
                })
                .sort((a, b) => a.price - b.price)

        countConfigs(FilterConditions.length)

        if (this.props.usdRate !== undefined && this.props.list !== undefined) {
            const jsonLdData = this.generateJsonLd(this.props.list);
            this.props.onJsonLdGenerated(jsonLdData);
            }

        return (

<div className="fullwidth light">
{FilterConditions.length > 0 ? FilterConditions.map((item,i) =>
    <div className="row" key={i}>
        <div className="twelve columns">
            <div className="panel pricing">
                <div className="twelve columns">
                    <div className="eight columns">
                        <h3 className="config">
                            <span className="colored">
                                {item?.cpu?.data?.cpu_count || '-'} x {item?.cpu_det?.name || '-'}, {item?.ram_gb || '-'} GB,
                                {item?.storage?.data?.map((e, is) =>
                                    <span
                                        key={is}>&nbsp;{e?.number || '1'} x {e?.gb || '0'} GB {e?.type || ''}</span>
                                )}
                            </span>
                        </h3>
                    </div>
                    <div className='four columns'>
                    {+(item?.is_stock_quantity || 0) < 1
                    ? (<span className="tag right"><i className="fas fa-history preorder"></i> Pre-order</span>)
                    : (<span className="tag right"><span>IN STOCK:</span> {+(item?.is_stock_quantity || 0) === 1
                        ?
                            <span className='one'>ONE</span>
                        : +(item?.is_stock_quantity || 0) > 1 && +(item?.is_stock_quantity || 0) < 10
                            ?   <span className='few'>FEW</span>
                            :   <span className='many'>MANY</span>
                        }
                        </span>
                        )
                    }
                    {type === 'gpu' &&
                        <span className="tag right">GPU</span>
                    }
                    </div>
                </div>
                <hr className="black"/>
                <div className="eight columns">
                    <ul className="dp">
                        <li>
                            <span className="name"><i className="fa fa-caret-right colored" aria-hidden="true"></i>CPU Model & Cores</span>
                            <span
                                className="value">{item?.cpu?.data?.cpu_count || '-'} x {item?.cpu_det?.manufacturer || '-'} {item?.cpu_det?.name || '-'} {item?.cpu_det?.cores || '-'} cores, {item?.cpu_det?.threads || '-'} threads, {item?.cpu_det?.frequency || '0'} GHz</span>
                        </li>
                        <li>
                            <span className="name"><i className="fa fa-caret-right colored"
                                                      aria-hidden="true"></i>RAM</span>
                            <span className="value">{item?.ram_gb || '-'} GB</span>
                        </li>
                        <li>
                            <span className="name"><i className="fa fa-caret-right colored" aria-hidden="true"></i>Storage</span>
                            {item?.storage?.data?.map((e, is) =>
                                <span
                                    className="slash"
                                    key={is}>{e?.number || '1'} x {e?.gb || '0'} GB {e?.type || ''}</span>
                            )}
                        </li>
                        {type === 'gpu' &&
                            <li>
                                <span className="name"><i className="fa fa-caret-right colored" aria-hidden="true"></i>GPU</span>
                                <span
                                    className="slash">{item?.gpu}</span>
                            </li>
                        }
                        <li>
                            <span className="name"><i className="fa fa-caret-right colored"
                                                      aria-hidden="true"></i>Uplink capacity</span>
                            {isStock ?
                                <span
                                    className="value">{item?.uplinkCapacity >= 1 ? `${(+item?.uplinkCapacity)} Gbps` : `${(+item?.uplinkCapacity) * 1000} Mbps`}</span>
                                :
                                <span className="value">up to 100 Gbps</span>
                            }
                        </li>
                        <li>
                            <span className="name"><i className="fa fa-caret-right colored"
                                                      aria-hidden="true"></i>Bandwidth and IP</span>
                            {isStock ?
                                <span
                                    className="value">15 TB monthly @ {item?.uplinkCapacity >= 1 ? `${(+item?.uplinkCapacity)} Gbps` : `${(+item?.uplinkCapacity) * 1000} Mbps`} / 1 IPv4 address</span>
                                :
                                <span className="value">15 TB monthly @ up to 100 Gbps / 1 IPv4 address</span>
                            }
                        </li>
                        <li>
                            <span className="name"><i className="fa fa-caret-right colored"
                                                      aria-hidden="true"></i>Stock</span>
                            {isStock ?
                                <span
                                    className="value">In stock / 4-hour delivery</span>
                                :
                                <span className="value">In stock / Up to 48-hour delivery</span>
                            }
                        </li>
                    </ul>
                    <span className="expand-products" title="View configuration details"
                                  onClick={this.toggleAnimation} animation-id={item.id}><i
                        className="fa fa-caret-down colored" aria-hidden="true"></i>View all specifications</span>
                    <ul className={"nobullet co expandInfo"}
                        id={"details-" + item.id}>
                        <li>
                            <span className="name">Server Location</span><span>Zurich, Switzerland</span>
                        </li>
                        <li>
                        <span className="name">Support</span><span>24/7 Live</span>
                        </li>
                        <li>
                            <span className="name">KVM/IPMI</span><span>Free of charge</span>
                        </li>
                        <li>
                            <span className="name">Full Disk Encryption</span><span>Supported</span>
                        </li>
                        <li>
                            <span className="name">Billing Options</span><span>Monthly, quarterly, semi-annual, annual</span>
                        </li>
	                </ul>
                </div>
                <div className="four columns">
                    <div className="row">
                        <div className="twelve columns monthly-fee margintop">

                            <span className="chf">
                                <React.Fragment>
                                    {new Intl.NumberFormat('de-CH', { style: 'currency', currency: 'CHF' }).format(item.price)}
                                </React.Fragment>
                            </span>
                        <div className="usd">USD <span className="val">
                            {new Intl.NumberFormat("de-CH", {
                                style: "decimal",
                                maximumFractionDigits: 2
                            }).format(item.price * usdRate)}
                            </span>
                        </div>
                        <div className="eur">EUR <span className="val">{
                            new Intl.NumberFormat("de-CH", {
                                    style: "decimal",
                                    maximumFractionDigits: 2
                                }).format(item.price * eurRate)
                            }</span>
                        </div>
                            <span className="month">per month</span>
                        </div>
                    </div>
                    <span className="link-for-storage_w"><a className="button radius large right" href={"//" + process.env.REACT_APP_MEMBER_DOMAIN + "/en/cart/add/" + (+(item?.id || 0) + 10000)} title="Configure and place order">Configure</a></span>
                </div>
            </div>
        </div>
    </div>
)
    :
    <div className="row">
        <div className="twelve columns">
            <div className="panel pricing">
                <h3>No Match</h3>
                <p>No dedicated server configurations match your requirements. Please relax search parameters or reset the filters.</p>
            </div>
        </div>
    </div>
}
</div>
            )
    }
    toggleAnimation = (e) => {
    document.getElementById("details-"+e.target.getAttribute('animation-id')).classList.toggle('expanded')
      }
}

export default withRouter(DedicatedServersListInstant);
